import request from '@/utils/request'
// 分页列表
export function getSelectRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/getselectlisttype',
    method: 'get',
    params
  })
}
// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/getList',
    method: 'get',
    params
  })
}



// 添加 
export function addtypeRequest(data) {
  return request({
    url: '/admin/zr_productHDMS/add',
    method: 'post',
    data
  })
}


// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/del',
    method: 'get',
    params
  })
}
// 获取列表 商品
export function getselectproductlist(params) {
  return request({
    url: '/admin/zr_giftbag/getselectproductlist',
    method: 'get',
    params
  })
}
// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/zr_productHDMS/edit',
    method: 'post',
    data
  })
}



// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/setStates',
    method: 'get',
    params
  })
}

// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/zr_productHDMS/setSort',
    method: 'get',
    params
  })
}